var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ol',{staticClass:"progress"},[(
        _vm.$route.name === 'entCertification' || _vm.$route.name === 'entCertForm'
      )?_c('div',{staticClass:"btn"},[_c('a-button',{staticStyle:{"margin-top":"25px","margin-left":"20px","color":"#007ce2"},on:{"click":function($event){return _vm.$router.replace({ path: '/orderCenter'})}}},[_vm._v(" 返回 ")])],1):_vm._e(),_vm._l((_vm.stepList),function(item,index){return _c('li',{key:_vm.getName(item),class:['step', _vm.activeIndex === index ? 'active' : '']},[_c('i',[_vm._v(_vm._s(_vm.activeIndex > index ? '✓' : index + 1))]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.getName(item))+" ")]),(item.time)?_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(item.time)+" ")]):_vm._e()]),_c('div',{staticClass:"arrow",class:(_vm.$route.name === 'entCertification' ||
            _vm.$route.name === 'entCertForm') &&
          index === 0
            ? 'noarrow'
            : ''})])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }