<template>
  <div>
    <ol class="progress">
      <div
        class="btn"
        v-if="
          $route.name === 'entCertification' || $route.name === 'entCertForm'
        "
      >
        <a-button
          style="margin-top: 25px; margin-left: 20px; color: #007ce2"
          @click="$router.replace({ path: '/orderCenter'})"
        >
          返回
        </a-button>
      </div>
      <li
        :class="['step', activeIndex === index ? 'active' : '']"
        v-for="(item, index) in stepList"
        :key="getName(item)"
      >
        <i>{{ activeIndex > index ? '&#10003;' : index + 1 }}</i>
        <div class="main">
          <div class="name">
            {{ getName(item) }}
          </div>
          <div class="time" v-if="item.time">
            {{ item.time }}
          </div>
        </div>
        <div
          class="arrow"
          :class="
            ($route.name === 'entCertification' ||
              $route.name === 'entCertForm') &&
            index === 0
              ? 'noarrow'
              : ''
          "
        />
      </li>
    </ol>
  </div>
</template>

<script>

export default {
  name: 'ProgressPurchase',
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
    stepList: {
      type: Array,
      default () {
        return [];
      },
    },
  },
  methods: {
    getName (item) {
      return typeof (item) === 'object' ? item.name : item;
    },
  },
};
</script>

<style lang="less" scope>
.progress {
  overflow: hidden;
  border-bottom: 1px solid #f0f0f0;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  background-color: #fff;
  .step {
    flex: 1;
    padding: 17px 0;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    i {
      display: inline-block;
      width: 36px;
      background: #ffffff;
      border: 2px solid #007ce2;
      border-radius: 50%;
      font-style: normal;
      font-size: 24px;
      color: #007ce2;
      line-height: 36px;
      box-sizing: content-box;
      vertical-align: middle;
    }
    .main {
      margin-left: 25px;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      .name {
        font-size: 16px;
        color: #333;
      }
      .time {
        margin-top: 3px;
        font-size: 12px;
        color: #999;
      }
    }
    .arrow,
    .arrow::after {
      width: 0;
      height: 0;
      border-top: 42px solid transparent;
      border-left: 15px solid #eaeaea;
      border-bottom: 42px solid transparent;
    }
    .arrow {
      position: absolute;
      left: 0;
      top: -5px;
      &::after {
        position: absolute;
        left: -17px;
        top: -42px;
        content: ' ';
        border-left-color: #fff;
      }
    }
    &:first-child .arrow {
      display: none;
    }
  }
  .step.active {
    background: linear-gradient(to right, rgba(0, 124, 226, 0.1) 50%, #fff 70%);
    &:first-child {
      border-left: 2px solid #007ce2;
    }
    i {
      position: relative;
      background-color: #007ce2;
      color: #fff;
      &::after {
        position: absolute;
        right: -7px;
        top: 12px;
        content: ' ';
        border-top: 7px solid transparent;
        border-left: 7px solid #007ce2;
        border-bottom: 7px solid transparent;
      }
    }
    .arrow {
      border-left: 15px solid #007ce2;
    }
    // & + .step{
    //   .arrow{
    //     border-left: 15px solid #007CE2;
    //   }
    // }
  }
}
.noarrow {
  display: none;
}
.btn .ant-btn {
  width: 100px !important;
  height: 36px !important;
  margin-top: 20px !important;
  color: #007ce2;
  font-size: 14px !important;
  border: 1px solid #007ce2 !important;
  border-radius: 3px !important;
}
</style>
